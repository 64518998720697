<template>
  <div>
    <b-card-code :title="$t('Manage') + ' ' + $t('Parking Lots')">
      <!-- search input -->
      <div class="custom-search d-flex justify-content-between">
        <div>
          <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" v-b-modal.modal-create-parkingLot
            @click="onCreateModalOpen">
            <feather-icon icon="PlusIcon" class="mr-50" />
            <span class="align-middle">{{$t('Add')}}</span>
          </b-button>
        </div>
        <b-form-group>
          <div class="d-flex align-items-center">
            <label class="mr-1">{{$t('Search')}}</label>
            <b-form-input v-model="searchTerm" :placeholder="$t('Search')" type="text" class="d-inline-block" />
          </div>
        </b-form-group>
      </div>

      <!-- table -->
      <vue-good-table :columns="columns" :isLoading="loadingParkingLots" :rows="parkingLots" :search-options="{
        enabled: true,
        externalQuery: searchTerm,
      }" :pagination-options="{
  enabled: true,
  perPage: pageLength,
}">
        <!-- Slot: Table Column -->
        <template
          slot="table-column"
          slot-scope="props"
        >
          <span
            v-if="props.column.label ==='Name'"
            class="text-nowrap"
          >
            {{ $t('Name') }}
          </span>
          <span
            v-else-if="props.column.label ==='Principal'"
            class="text-nowrap"
          >
            {{ $t('Principal') }}
          </span>
          <span
            v-else-if="props.column.label ==='Action'"
            class="text-nowrap"
          >
            {{ $t('Action') }}
          </span>
          <span v-else>
            {{ props.column.label }}
          </span>
        </template>

        <template slot="table-row" slot-scope="props">
          <!-- Column: No -->
          <span v-if="props.column.field === 'index'">
            {{ props.index + 1 }}
          </span>
          <!-- Column: Action -->
          <span v-if="props.column.field === 'action'" class="action-btns">
            <b-button v-ripple.400="'rgba(40, 199, 111, 0.15)'" variant="flat-primary" v-b-modal.snapshot-scene-viewer
              size="sm" @click="setSelectedParkingLot(props.row)">
              <feather-icon icon="ImageIcon" class="mr-50" />
              <span class="align-middle">{{$t('Scenes')}}</span>
            </b-button>
            <b-button v-ripple.400="'rgba(40, 199, 111, 0.15)'" variant="flat-success" v-b-modal.modal-edit-parkingLot
              size="sm" @click="setEditParkingLot(props.row)">
              <feather-icon icon="Edit2Icon" class="mr-50" />
              <span class="align-middle">{{$t('Edit')}}</span>
            </b-button>
            <b-button v-ripple.400="'rgba(40, 199, 111, 0.15)'" variant="flat-danger" size="sm"
              @click="removeParkingLot(props.row)">
              <feather-icon icon="TrashIcon" class="mr-50" />
              <span class="align-middle">{{$t('Delete')}}</span>
            </b-button>
          </span>

          <!-- Column: Common -->
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>

        <!-- pagination -->
        <template slot="pagination-bottom" slot-scope="props">
          <div class="d-flex justify-content-between flex-wrap">
            <div class="d-flex align-items-center mb-0 mt-1">
              <span class="text-nowrap"> {{$t('Showing')}} 1 {{$t('to')}} </span>
              <b-form-select v-model="pageLength" :options="['3', '5', '10']" class="mx-1" @input="
                (value) => props.perPageChanged({ currentPerPage: value })
              " />
              <span class="text-nowrap"> {{$t('of')}} {{ props.total }} {{$t('entries')}} </span>
            </div>
            <div>
              <b-pagination :value="1" :total-rows="props.total" :per-page="pageLength" first-number last-number
                align="right" prev-class="prev-item" next-class="next-item" class="mt-1 mb-0"
                @input="(value) => props.pageChanged({ currentPage: value })">
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </div>
          </div>
        </template>
      </vue-good-table>
    </b-card-code>

    <!-- create parkingLot modal-->
    <b-modal id="modal-create-parkingLot" cancel-variant="outline-secondary" :ok-title="$t('Create')" :cancel-title="$t('Cancel')"
      :title="$t('Create') + ' ' + $t('Parking Lot')" centered no-close-on-backdrop ref="create-parkingLot-modal"
      :ok-disabled="createLoading" @ok="createParkingLot" @cancel="onCreateModalClosed"
      @hidden="onCreateModalClosed">
      <validation-observer ref="createParkingLotRules">
        <b-form>
          <b-form-group>
            <validation-provider #default="{ errors }" name="Name" rules="required">
              <label for="name">{{$t('Name')}}</label>
              <b-form-input v-model="newParkingLot.parkingLot" id="name" type="text"
                :state="errors.length > 0 ? false : null" :placeholder="$t('Parking Lot') + ' ' + $t('name')" rules="required" />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group>
            <validation-provider #default="{ errors }" name="Principal" rules="required">
              <label for="principal">{{$t('Principal')}}</label>
              <v-select id="scenes" :value="newParkingLot.principal" :options="principals" label="name"
                @input="onCreatePrincipalChange" :placeholder="$t('Select') + ' ' + $t('Principal')" />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group>
            <label for="address">{{$t('Address')}}</label>
            <!-- <b-form-input v-model="newParkingLot.address" id="address" type="text" placeholder="Address"
              style="margin-bottom: 5px" /> -->
            <b-input-group>
            <gmap-autocomplete ref="gmapAutocompleteCreate" class="form-control" :placeholder="$t('Search address')"
              @place_changed="usePlace" :options="{ fields: ['geometry', 'formatted_address', 'address_components'] }"
              :select-first-on-enter="true">
            </gmap-autocomplete>
            </b-input-group>

            <GmapMap ref="mapRefCreate" :center="centerMapCreate" :zoom="18" style="width: 100%; height: 400px"
              @click="handleMapClickOnCreate">
              <GmapMarker :key="index" v-for="(m, index) in markers" :position="m.position" :clickable="true"
                :draggable="true" @click="center = m.position" />
            </GmapMap>
          </b-form-group>
          <!-- <b-form-group>
            <label for="scenes">{{$t('Scenes')}}</label>
            <v-select
              id="scenes"
              multiple
              v-model="newParkingLot.scenes"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="scenes"
              label="name"
              :placeholder="$t('Select') + ' ' + $t('Scenes')"
            >
              <template #option="{ name, parkingLot }">
                <span>
                  <b>{{ name }}</b> ({{
                    parkingLot ? parkingLot.name : "No Parking Lot"
                  }})</span
                >
              </template>
            </v-select>
          </b-form-group> -->
        </b-form>
      </validation-observer>
    </b-modal>

    <!-- edit parkingLot modal-->
    <b-modal id="modal-edit-parkingLot" cancel-variant="outline-secondary" :ok-title="$t('Update')" :cancel-title="$t('Cancel')"
      :title="$t('Update') + ' ' + $t('Parking Lot')" centered no-close-on-backdrop ref="edit-parkingLot-modal" :ok-disabled="editLoading"
      @ok="updateParkingLot" @cancel="onEditModalClosed"
      @hidden="onEditModalClosed">
      <validation-observer ref="editParkingLotRules">
        <b-form>
          <b-form-group>
            <validation-provider #default="{ errors }" name="Name" rules="required">
              <label for="name">{{$t('Name')}}</label>
              <b-form-input v-model="editParkingLot.newParkingLot" id="name" type="text"
                :state="errors.length > 0 ? false : null" :placeholder="$t('Parking Lot') + ' ' + $t('name')" rules="required" />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group>
            <validation-provider #default="{ errors }" name="Principal" rules="required">
              <label for="principal">{{$t('Principal')}}</label>
              <v-select id="scenes" :value="editParkingLot.newPrincipal" @input="onEditPrincipalChange"
                :options="principals" label="name" />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group>
            <label for="address">{{$t('Address')}}</label>
            <!-- <b-form-input v-model="editParkingLot.address" id="address" type="text" placeholder="Address"
              style="margin-bottom: 5px" /> -->
            <b-input-group>
              <gmap-autocomplete ref="gmapAutocompleteEdit" class="form-control" :placeholder="$t('Search address')" @place_changed="usePlace"
                :options="{ fields: ['geometry', 'formatted_address', 'address_components'] }"
                :select-first-on-enter="true">
              </gmap-autocomplete>
            </b-input-group>

            <GmapMap ref="mapRefEdit" :center="centerMapEdit" :zoom="18" style="width: 100%; height: 400px"
              @click="handleMapClickOnEdit">
              <GmapMarker :key="index" v-for="(m, index) in markers" :position="m.position" :clickable="true"
                :draggable="true" @click="center = m.position" />
            </GmapMap>
          </b-form-group>

          <b-form-group>
            <label for="scenes">{{$t('Scenes')}}</label>
            <v-select id="scenes" multiple v-model="editParkingLot.scenes"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="scenes" label="name" disabled
              class="scenes-select">
              <template #option="{ name, parkingLot }">
                <span>
                  <b>{{ name }}</b> ({{
  parkingLot? parkingLot.name : "No Parking Lot"
                  }})</span>
              </template>
            </v-select>
          </b-form-group>
        </b-form>
      </validation-observer>
    </b-modal>

    <!-- manage snapshot/space modal-->
    <b-modal id="snapshot-scene-viewer" cancel-variant="outline-secondary" :title="$t('View scenes')" centered
      no-close-on-backdrop ok-only :dialog-class="spacesViewerModalClass" ref="snapshot-scene-viewer"
      @cancel="onSnapshotManageModalClosed" @close="onSnapshotManageModalClosed" @ok="onSnapshotManageModalClosed">
      <validation-observer ref="snapshotSpaceRules">
        <b-form>
          <b-form-group>
            <div class="align-items-baseline d-flex justify-content-between">
              <label for="Snapshot">{{$t('Snapshot')}}</label>
              <b-button v-ripple.400="'rgba(40, 199, 111, 0.15)'" variant="flat-success"
                :class="`btn-icon ${snapshotViewMax ? 'max-icon' : ''}`" v-b-tooltip.hover.top="
                  snapshotViewMax ? $t('Minimize snapshot') : $t('Maximize snapshot')
                " @click="maxMinSnapshot">
                <feather-icon style="width: 20px; height: 20px;"
                  :icon="snapshotViewMax ? 'MinimizeIcon' : 'MaximizeIcon'" />
              </b-button>
            </div>
            <div v-if="selectedScene && selectedScene.snapshot">
              <!-- overlay on fullscreen snapshot -->
              <div v-if="snapshotViewMax" style="
                  position: fixed;
                  top: 0;
                  left: 0;
                  width: 100%;
                  height: 100%;
                  background: #f8f8f8;
                ">
              </div>
              <div v-if="handlingSnapshotSize" style=" position: fixed; top: 0; left: 0; width: 100%; height: 100%; background: #f8f8f8;z-index: 2; display: flex; align-items: center; justify-content: center;
                ">
                <b-spinner label="Loading..." style="width: 100px; height: 100px" />
              </div>

              <div :class="
                'snapshot-wrapper ' +
                (snapshotViewMax
                  ? 'snapshot-wrapper-maximized'
                  : 'snapshot-wrapper-minimized')
              ">
                <b-img :class="
                  snapshotViewMax
                    ? 'snapshot-img-maximized'
                    : 'snapshot-img-minimized'
                " id="snapshot-img" thumbnail fluid :src="getSnapshotImage()" ref="selectedSnapshot"
                  @load="snapshotLoad" @error="snapshotLoadFailed" />
              </div>

              <div v-for="space in getSpacesByScene()" :key="space.id">
                <div v-if="snapshotLoadDone && space.coords.length >= 4">
                  <fabric-canvas :height="snapshotHeight + 10" :width="snapshotWidth + 10" :class="
                    'fabric-canvas-itself ' +
                    (snapshotViewMax
                      ? 'canvas-maximized'
                      : 'canvas-minimized')
                  " :id="'canvas' + space.id">
                    <template>
                      <!-- lines -->
                      <fabric-line :key="'topLine' + space.id" :id="'topLine' + space.id" :x1="space.coords[0].xvalue"
                        :y1="space.coords[0].yvalue" :x2="space.coords[1].xvalue" :y2="space.coords[1].yvalue" :fill="
                          getZoneBySpace(space)
                            ? getZoneBySpace(space).name
                            : lineFill
                        " :stroke="
  getZoneBySpace(space)
    ? getZoneBySpace(space).name
    : lineFill
" :strokeWidth="strokeWidth" :selectable="false" :evented="false" :originX="'center'"
                        :originY="'center'"></fabric-line>
                      <fabric-line :key="'leftLine' + space.id" :id="'leftLine' + space.id" :x1="space.coords[0].xvalue"
                        :y1="space.coords[0].yvalue" :x2="space.coords[2].xvalue" :y2="space.coords[2].yvalue" :fill="
                          getZoneBySpace(space)
                            ? getZoneBySpace(space).name
                            : lineFill
                        " :stroke="
  getZoneBySpace(space)
    ? getZoneBySpace(space).name
    : lineFill
" :strokeWidth="strokeWidth" :selectable="false" :evented="false" :originX="'center'"
                        :originY="'center'"></fabric-line>
                      <fabric-line :key="'rightLine' + space.id" :id="'rightLine' + space.id"
                        :x1="space.coords[1].xvalue" :y1="space.coords[1].yvalue" :x2="space.coords[3].xvalue"
                        :y2="space.coords[3].yvalue" :fill="
                          getZoneBySpace(space)
                            ? getZoneBySpace(space).name
                            : lineFill
                        " :stroke="
  getZoneBySpace(space)
    ? getZoneBySpace(space).name
    : lineFill
" :strokeWidth="strokeWidth" :selectable="false" :evented="false" :originX="'center'"
                        :originY="'center'"></fabric-line>
                      <fabric-line :key="'bottomLine' + space.id" :id="'bottomLine' + space.id"
                        :x1="space.coords[2].xvalue" :y1="space.coords[2].yvalue" :x2="space.coords[3].xvalue"
                        :y2="space.coords[3].yvalue" :fill="
                          getZoneBySpace(space)
                            ? getZoneBySpace(space).name
                            : lineFill
                        " :stroke="
  getZoneBySpace(space)
    ? getZoneBySpace(space).name
    : lineFill
" :strokeWidth="strokeWidth" :selectable="false" :evented="false" :originX="'center'"
                        :originY="'center'"></fabric-line>

                      <!-- circles -->
                      <fabric-circle :key="'topLeft' + space.id" :id="'topLeft' + space.id"
                        :left.sync="space.coords[0].xvalue" :top.sync="space.coords[0].yvalue"
                        :strokeWidth="strokeWidth" :radius="circleRadius" :fill="'#fff'" :stroke="'#666'"
                        :originX="'center'" :originY="'center'" :hasControls="false" :selectable="false"
                        :hoverCursor="false ? 'all-scroll' : 'normal'" @moved="circleMoved(space)"></fabric-circle>
                      <fabric-circle :key="'topRight' + space.id" :id="'topRight' + space.id"
                        :left.sync="space.coords[1].xvalue" :top.sync="space.coords[1].yvalue"
                        :strokeWidth="strokeWidth" :radius="circleRadius" :fill="'#fff'" :stroke="'#666'"
                        :originX="'center'" :originY="'center'" :hasControls="false" :selectable="false"
                        :hoverCursor="false ? 'all-scroll' : 'normal'"></fabric-circle>
                      <fabric-circle :key="'bottomLeft' + space.id" :id="'bottomLeft' + space.id"
                        :left.sync="space.coords[2].xvalue" :top.sync="space.coords[2].yvalue"
                        :strokeWidth="strokeWidth" :radius="circleRadius" :fill="'#fff'" :stroke="'#666'"
                        :originX="'center'" :originY="'center'" :hasControls="false" :selectable="false"
                        :hoverCursor="false ? 'all-scroll' : 'normal'"></fabric-circle>
                      <fabric-circle :key="'bottomRight' + space.id" :id="'bottomRight' + space.id"
                        :left.sync="space.coords[3].xvalue" :top.sync="space.coords[3].yvalue"
                        :strokeWidth="strokeWidth" :radius="circleRadius" :fill="'#fff'" :stroke="'#666'"
                        :originX="'center'" :originY="'center'" :hasControls="false" :selectable="false"
                        :hoverCursor="false ? 'all-scroll' : 'normal'"></fabric-circle>

                      <!-- License Plate -->
                      <fabric-text :key="'LicensePlate' + space.id" :id="'LicensePlate' + space.id"
                        :text="getLicensePlateBySpace(space)" :fontSize="16" :fill="
                          getZoneBySpace(space)
                            ? getZoneBySpace(space).name
                            : lineFill
                        " :fontWeight="'bold'" textBackgroundColor="rgb(255 255 255 / 40%)"
                        :left="space.coords[2].xvalue" :top="space.coords[2].yvalue" :hasControls="false"
                        :selectable="false" hoverCursor="normal"></fabric-text>

                      <!-- space name -->
                      <fabric-text :key="'spacename' + space.id" :id="'spaceName' + space.id"
                        :text="'(' + space.name + ')'" :fontSize="16" :fill="
                          getZoneBySpace(space)
                            ? getZoneBySpace(space).name
                            : lineFill
                        " :fontWeight="'bold'" textBackgroundColor="rgb(255 255 255 / 40%)"
                        :left="space.coords[2].xvalue"
                        :top="space.coords[2].yvalue + (getLicensePlateBySpace(space) == '' ? 0 : 17)"
                        :hasControls="false" :selectable="false" hoverCursor="normal"></fabric-text>
                    </template>
                  </fabric-canvas>
                </div>
              </div>
            </div>
            <div v-else class="d-flex justify-content-center mb-1">
              <p v-if="selectedScene == null" style="font-size: 14px; color: black; margin-bottom: 0">
                {{$t('Please select a Scene to see Snapshot and Spaces')}}
              </p>
              <p v-else-if="nonExistDevicesDataRecently" style="font-size: 14px; margin-bottom: 0; color: #ff5722;">
                {{$t('Device is not connected')}}
              </p>
              <b-spinner v-else label="Loading..." />
            </div>
          </b-form-group>

          <!-- scenes select -->
          <b-form-group>
            <validation-provider #default="{ errors }" name="scene" rules="required">
              <label for="scene">{{$t('Scene')}}</label>
              <v-select id="scene" v-model="selectedScene" :options="getScenesHasDevice(selectedParkingLot.scenes)"
                @input="onSelectedSceneChange" :clearable="false" label="name" :placeholder="$t('Select a scene')" />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-form>
      </validation-observer>
    </b-modal>
  </div>
</template>

<script>
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import {
  BAvatar,
  BBadge,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BDropdown,
  BDropdownItem,
  BButton,
  BModal,
  BForm,
  VBModal,
  BInputGroup,
  BInputGroupAppend,
  BInputGroupPrepend,
  BSpinner,
  BImg,
  VBTooltip,
} from "bootstrap-vue";
import { VueGoodTable } from "vue-good-table";
import store from "@/store/index";
import Ripple from "vue-ripple-directive";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, email, confirmed, password } from "@validations";
import vSelect from "vue-select";
import { gmapApi } from "vue2-google-maps";
import vueFabricWrapper from "vue-fabric-wrapper";

export default {
  components: {
    BCardCode,
    VueGoodTable,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BButton,
    BModal,
    BForm,
    vSelect,
    ValidationProvider,
    ValidationObserver,
    BInputGroup,
    BInputGroupAppend,
    BInputGroupPrepend,
    BSpinner,
    BImg,
    FabricCanvas: vueFabricWrapper.FabricCanvas,
    FabricCircle: vueFabricWrapper.FabricCircle,
    FabricLine: vueFabricWrapper.FabricLine,
  },
  directives: {
    "b-modal": VBModal,
    'b-tooltip': VBTooltip,
    Ripple,
  },
  data() {
    return {
      search_address: "",
      prevResolutionRate: 1,
      snapshotLoadDone: false,

      currentModal: null,
      mapCurrentPlace: null,
      description: "",
      markers: [],
      showMap: false,
      centerMapCreate: { lat: 45.508, lng: -73.587 },
      centerMapEdit: { lat: 45.508, lng: -73.587 },
      newParkingLot: {
        parkingLot: "",
        gpsLocation: "",
        principal: "",
        scenes: [],
      },
      editParkingLot: {
        parkingLot: "",
        gpsLocation: "",
        principal: "",
        scenes: [],
      },
      createLoading: false,
      editLoading: false,
      pageLength: 10,
      dir: false,
      columns: [
        // {
        //   label: "No",
        //   field: "index",
        // },
        {
          label: "Name",
          field: "name",
        },
        // {
        //   label: "GPS Location",
        //   field: "gpsLocation",
        // },
        {
          label: "Principal",
          field: "principal.name",
          // width: '50%',
        },
        {
          label: "Action",
          field: "action",
          // width: "250px",
        },
      ],
      rows: [],
      searchTerm: "",
      selectedScene: null,
      selectedParkingLot: {},
      snapshotInterval: null,
      commandGuid: null,

      snapshotWidth: 500,
      snapshotHeight: 500,

      strokeWidth: 3,
      lineFill: "red",
      lineStroke: "red",
      circleRadius: 5,

      snapshotViewMax: false,
      spacesViewerModalClass: "minimized",
      naturalWidth: 0,
      naturalHeight: 0,
      viewingWidth: 0,
      viewingHeight: 0,

      nonExistDevicesDataRecently: false,
      handlingSnapshotSize: false,
    };
  },
  computed: {
    selectedPrincipal() {
      return this.$store.state.global.selectedPrincipal;
    },
    g_selectedPrincipal() {
      return this.$store.state.global.selectedPrincipal;
    },
    g_selectedParkingLot() {
      return this.$store.state.global.selectedParkingLot;
    },
    parkingLots() {
      const filtered = this.$store.state.parkingLot.parkingLots.filter((p) => {
        return (
          (this.g_selectedParkingLot == null ||
            p.id == this.g_selectedParkingLot?.id) &&
          (this.g_selectedPrincipal == null ||
            p.principal?.id == this.g_selectedPrincipal?.id)
        );
      });
      return filtered;
    },

    loadingParkingLots() {
      return this.$store.state.parkingLot.loadingParkingLots;
    },
    principals() {
      return this.$store.state.principal.principals;
    },
    scenes() {
      return this.$store.state.scene.scenes;
    },
    zones() {
      return this.$store.state.zone.zones;
    },
    occupancys() {
      return this.$store.state.device.occupancys;
    },
    google: gmapApi,
  },
  beforeDestroy() {
    this.stopSnapshotInterval(this.snapshotInterval);
  },
  async created() {
    await this.$store.dispatch("parkingLot/get", this.$bvToast);
    await this.$store.dispatch("principal/get", this.$bvToast);
    await this.$store.dispatch("zone/get", this.$bvToast);
    await this.$store.dispatch("scene/get", { bvToast: this.$bvToast });
    await this.$store.dispatch("device/getOccupancys", this.$bvToast);
  },
  mounted() { },
  methods: {
    onCreateModalClosed(){
      this.markers = []
    },
    onEditModalClosed(){
      this.markers = []
    },
    onEditPrincipalChange(selectedPrincipal) {
      console.log('onEditPrincipalChange', selectedPrincipal)
      this.editParkingLot.newPrincipal = selectedPrincipal
      if(selectedPrincipal) {
        this.editParkingLot.address = `${selectedPrincipal.city.name}, ${selectedPrincipal.country.name}`;
  
        var geocoder = new this.google.maps.Geocoder();
  
        const that = this;
        geocoder.geocode({ 'address': this.editParkingLot.address }, function (results, status) {
          if (status == that.google.maps.GeocoderStatus.OK) {
            var latitude = results[0].geometry.location.lat();
            var longitude = results[0].geometry.location.lng();
            that.editParkingLot.gpsLocation = `${latitude}, ${longitude}`
  
            that.centerMapEdit = {
              lat: latitude,
              lng: longitude,
            };
            that.$refs.gmapAutocompleteEdit.$el.value = that.editParkingLot.address
  
            that.markers = [
              new that.google.maps.Marker({
                map: that.$refs.mapRefEdit,
                position: { lat: that.centerMapEdit.lat, lng: that.centerMapEdit.lng },
                title: "Selected location",
              }),
            ];
          }
        });
      } else {
        this.editParkingLot.address = ""
      }

    },
    onCreatePrincipalChange(selectedPrincipal) {
      console.log('onCreatePrincipalChange', selectedPrincipal)
      this.newParkingLot.principal = selectedPrincipal
      if (selectedPrincipal) {
        this.newParkingLot.address = `${selectedPrincipal.city?.name}, ${selectedPrincipal.country?.name}`;
  
        var geocoder = new this.google.maps.Geocoder();
  
        const that = this;
        geocoder.geocode({ 'address': this.newParkingLot.address }, function (results, status) {
          if (status == that.google.maps.GeocoderStatus.OK) {
            var latitude = results[0].geometry.location.lat();
            var longitude = results[0].geometry.location.lng();
            that.newParkingLot.gpsLocation = `${latitude}, ${longitude}`
  
            that.centerMapCreate = {
              lat: latitude,
              lng: longitude,
            };
  
            that.$refs.gmapAutocompleteCreate.$el.value = that.newParkingLot.address
  
            that.markers = [
              new that.google.maps.Marker({
                map: that.$refs.mapRefCreate,
                position: { lat: that.centerMapCreate.lat, lng: that.centerMapCreate.lng },
                title: "Selected location",
              }),
            ];
          }
        });
      } else {
        this.newParkingLot.address = ""
      }
      
    },
    maxMinSnapshot() {
      const img = document.getElementById("snapshot-img");
      this.snapshotViewMax = !this.snapshotViewMax;

      if (this.snapshotViewMax) {
        this.spacesViewerModalClass = "maximized";
      } else {
        this.spacesViewerModalClass = "minimized";
      }

      const that = this;
      this.handlingSnapshotSize = true;

      setTimeout(function () {
        that.handleImageSize(img);
        that.handlingSnapshotSize = false;
      }, 1000);
    },
    getScenesHasDevice(scenes) {
      const filtered = scenes.filter(item => item.deviceId != null && item.deviceId != undefined)
      return filtered;
    },
    snapshotLoadFailed(evt, b, c) {
      console.log("snapshotLoadFailed", evt, b, c);
    },
    getZoneBySpace(space) {
      return this.zones.find((item) => item.id == space.zoneType);
    },
    getLicensePlateBySpace(space) {
      const found = this.occupancys.find(item => item.scene_id == this.selectedScene?.id && item.space_id == space.id)
      return found ? found.license_plate : ""
    },
    getSpacesByScene() {
      console.log('getSpacesByScene')
      const spaces = [];

      const filtered = this.scenes.find(
        (item) => item.id == this.selectedScene?.id
      );

      if (filtered != null) {
        let resolutionRate = this.resolutionRate
        let snapshotHeight = this.viewingHeight
        let snapshotWidth = this.viewingWidth

        for (let i = 0; i < filtered.sceneSpaces.length; i++) {
          const sceneSpace = filtered.sceneSpaces[i];
          const space = JSON.parse(JSON.stringify(sceneSpace.space));

          if (space.coords.length == 4) {
            for (let i = 0; i < 4; i++) {
              space.coords[i].xvalue = space.resolutionApplied
                ? (space.coords[i].xvalue / this.prevResolutionRate) *
                resolutionRate
                : space.coords[i].xvalue * resolutionRate;

              space.coords[i].yvalue = space.resolutionApplied
                ? (space.coords[i].yvalue / this.prevResolutionRate) *
                resolutionRate
                : space.coords[i].yvalue * resolutionRate;
            }
            space.resolutionApplied = true;

            spaces.push(space);
          }
        }
      }

      return spaces;
    },
    async onSelectedSceneChange(scene) {
      this.stopSnapshotInterval(this.snapshotInterval);
      this.snapshotInterval = null;

      if (scene.deviceId) {
        this.selectedScene.snapshot = null;
        this.nonExistDevicesDataRecently = false;

        // get occupancies
        await this.$store.dispatch("device/getOccupancys", this.$bvToast);

        // create command
        this.$store
          .dispatch("device/setCommand", { deviceStreamKey: scene.deviceId })
          .then((response) => {
            if (response.data == false) {
              this.nonExistDevicesDataRecently = true;
            } else {
              this.commandGuid = response.data;
              this.startSnapshotInterval();
            }
          })
          .catch((err) => {
            this.$bvToast.toast(err.message, {
              title: `Error occured`,
              variant: "danger",
              solid: true,
              toaster: "b-toaster-bottom-right",
            });
          });
      } else {
        this.$bvToast.toast("This scene doesn't have device assigned", {
          title: `Error occured`,
          variant: "danger",
          solid: true,
          toaster: "b-toaster-bottom-right",
        });
      }
    },
    snapshotLoad(evt) {
      const img = document.getElementById('snapshot-img');
      this.handleImageSize(img);
    },
    handleImageSize(img) {
      this.naturalWidth = img.naturalWidth;
      this.naturalHeight = img.naturalHeight;

      this.viewingWidth = img.width;
      this.viewingHeight = img.height;

      this.snapshotHeight = this.viewingHeight;
      this.snapshotWidth = this.viewingWidth;

      this.snapshotLoadDone = true;
      console.log('img', img.naturalWidth, img.naturalHeight, img.width, img.height)
      this.resolutionRate = this.viewingWidth / this.naturalWidth;
    },
    getSnapshotImage() {
      return "data:image/jpg;base64, " + this.selectedScene?.snapshot;
      // if (this.isBase64(this.selectedScene?.snapshot)) {
      // } else {
      //   return this.selectedScene?.snapshot;
      // }
    },
    startSnapshotInterval() {
      this.snapshotInterval = setInterval(this.getSnapshot, 2000);
    },
    stopSnapshotInterval(interval) {
      window.clearInterval(interval);
    },
    getSnapshot() {
      const snapshotInterval = this.snapshotInterval;

      this.$store
        .dispatch("device/getSnapshot", {
          device_id: this.selectedScene.deviceId,
          commandGuid: this.commandGuid,
        })
        .then((response) => {
          console.log("snapshotInterval: ", snapshotInterval, this.snapshotInterval, snapshotInterval == this.snapshotInterval)
          if (snapshotInterval !== this.snapshotInterval) {
            this.stopSnapshotInterval(snapshotInterval);
          } else {
            if (response.data) {
              this.selectedScene.snapshot = response.data;
              this.stopSnapshotInterval(snapshotInterval);
            }
          }
        })
        .catch((err) => {
          this.createLoading = false;

          this.$bvToast.toast(err.message, {
            title: `Error occured`,
            variant: "danger",
            solid: true,
            toaster: "b-toaster-bottom-right",
          });
        });
    },
    onSnapshotManageModalClosed() {
      this.prevResolutionRate = 1;
      this.snapshotLoadDone = false;
      this.stopSnapshotInterval(this.snapshotInterval);
    },

    isBase64(str) {
      if (str == null || str === "" || str.trim() === "") {
        return false;
      }
      if (str.indexOf("http://") > -1 || str.indexOf("https://") > -1) {
        return false;
      } else {
        return true;
      }
    },
    onCreateModalOpen() {
      this.currentModal = 'create'
      if (this.selectedPrincipal) {
        this.onCreatePrincipalChange(this.selectedPrincipal)
      } else {
        this.newParkingLot.principal = this.selectedPrincipal
      }
    },
    usePlace(place) {
      console.log('usePlace', place)
      if (place) {
        const lat = place.geometry.location.lat()
        const lng = place.geometry.location.lng()

        if (this.currentModal == 'create') {
          this.updateMarkers(this.$refs.mapRefCreate, lat, lng)
          this.centerMapCreate = { lat, lng }

          this.newParkingLot.address = place.formatted_address
          this.newParkingLot.gpsLocation = lat + ", " + lng;
        } else {
          this.updateMarkers(this.$refs.mapRefEdit, lat, lng)
          this.centerMapEdit = { lat, lng }

          this.editParkingLot.address = place.formatted_address
          this.editParkingLot.gpsLocation = lat + ", " + lng;
        }

      }
    },
    updateMarkers(mapRef, lat, lng) {
      const that = this;
      for (let i = 0; i < this.markers.length; i++) {
        const marker = this.markers[i];
        marker.setMap(null);
      }

      mapRef.$mapPromise.then((map) => {
        that.markers = [
          new that.google.maps.Marker({
            map: map,
            position: { lat, lng },
            title: "Selected location",
          }),
        ];
      })

    },
    handleMapClickOnCreate(param) {
      const that = this
      const geocoder = new this.google.maps.Geocoder();

      geocoder.geocode({
        'latLng': param.latLng
      }, function (results, status) {
        if (status == that.google.maps.GeocoderStatus.OK) {
          if (results[0]) {
            that.newParkingLot.address = results[0].formatted_address
            that.newParkingLot.gpsLocation = param.latLng.lat() + ", " + param.latLng.lng();
            that.updateMarkers(that.$refs.mapRefCreate, param.latLng.lat(), param.latLng.lng())
          }
        }
      });
    },
    handleMapClickOnEdit(param) {
      const that = this
      const geocoder = new this.google.maps.Geocoder();

      geocoder.geocode({
        'latLng': param.latLng
      }, function (results, status) {
        if (status == that.google.maps.GeocoderStatus.OK) {
          if (results[0]) {
            that.editParkingLot.address = results[0].formatted_address
            that.editParkingLot.gpsLocation = param.latLng.lat() + ", " + param.latLng.lng();
            that.updateMarkers(that.$refs.mapRefEdit, param.latLng.lat(), param.latLng.lng())
          }
        }
      });
    },
    toggleMap(action) {
      this.showMap = !this.showMap;
      if (action == "onCreate") {
        this.markers = [
          new this.google.maps.Marker({
            map: this.$refs.mapRefCreate,
            position: this.centerMapCreate,
            title: "Selected location",
          }),
        ];
      }
    },
    createParkingLot(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.$refs.createParkingLotRules.validate().then((success) => {
        if (success) {
          this.createLoading = true;
          const data = {
            ...this.newParkingLot,
            address: this.$refs.gmapAutocompleteCreate.$el.value,
            principal: this.newParkingLot.principal?.name,
            scenes: this.newParkingLot.scenes.map((s) => s.id),
          };

          this.$store
            .dispatch("parkingLot/store", data)
            .then((response) => {
              this.$store.commit(
                "parkingLot/ADD_NEW_PARKINGLOT",
                response.data
              );
              this.createLoading = false;
              this.$refs["create-parkingLot-modal"].hide();

              this.$bvToast.toast("Parking Lot created successfully", {
                title: `Success`,
                variant: "success",
                solid: true,
              });
            })
            .catch((err) => {
              this.createLoading = false;

              this.$bvToast.toast(err.message, {
                title: `Error occured`,
                variant: "danger",
                solid: true,
                toaster: "b-toaster-bottom-right",
              });
            });
        }
      });
    },
    updateParkingLot(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.$refs.editParkingLotRules.validate().then((success) => {
        if (success) {
          this.editLoading = true;
          const data = {
            ...this.editParkingLot,
            address: this.$refs.gmapAutocompleteEdit.$el.value,
            newPrincipal: this.editParkingLot.newPrincipal?.name,
            scenes: this.editParkingLot.scenes.map((s) => s.id),
          };

          this.$store
            .dispatch("parkingLot/store", data)
            .then(async (response) => {
              this.$store.commit("parkingLot/EDIT_PARKINGLOT", response.data);
              this.editLoading = false;
              this.$refs["edit-parkingLot-modal"].hide();

              await this.$store.dispatch("parkingLot/get");
              await this.$store.dispatch("scene/get", {
                bvToast: this.$bvToast,
              });

              this.$bvToast.toast("Parking Lot updated successfully", {
                title: `Success`,
                variant: "success",
                solid: true,
              });
            })
            .catch((err) => {
              this.editLoading = false;

              this.$bvToast.toast(err.message, {
                title: `Error occured`,
                variant: "danger",
                solid: true,
                toaster: "b-toaster-bottom-right",
              });
            });
        }
      });
    },
    setEditParkingLot(row) {
      this.currentModal = 'update'
      this.editParkingLot = {
        parkingLot: row.name,
        newParkingLot: row.name,
        gpsLocation: row.gpsLocation,
        address: row.address,
        scenes: row.scenes,
        principal: row.principal.name,
        newPrincipal: row.principal,
      };

      const segs = row.gpsLocation ? row.gpsLocation.split(", ") : [0, 0];
      if (row.gpsLocation && segs.length >= 2) {
        this.centerMapEdit = {
          lat: parseFloat(segs[0]),
          lng: parseFloat(segs[1]),
        };
      } else {
        this.centerMapEdit = this.centerMapCreate;
      }

      this.$gmapApiPromiseLazy().then(() => {
        this.markers = [
          new this.google.maps.Marker({
            map: this.$refs.mapRefEdit,
            position: this.centerMapEdit,
            title: "Selected location",
          }),
        ];
      })

      setTimeout(()=> {
        this.$refs.gmapAutocompleteEdit.$el.value = row.address
      }, 1000)

      // this.updateMarkers(this.$refs.mapRefEdit, this.centerMapEdit?.lat, this.centerMapEdit?.lng)
    },
    setSelectedParkingLot(row) {
      this.selectedScene = null;
      this.selectedParkingLot = {
        parkingLot: row.name,
        gpsLocation: row.gpsLocation,
        scenes: [...row.scenes],
      };
      this.snapshotViewMax = false;
      this.spacesViewerModalClass = "minimized"
    },
    removeParkingLot(row) {
      this.$bvModal
        .msgBoxConfirm(this.$t('Are you sure?'), {
          title: this.$t('Please Confirm'),
          size: "sm",
          okVariant: "primary",
          okTitle: this.$t('Yes'),
          cancelTitle: this.$t('No'),
          cancelVariant: "outline-secondary",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value == true) {
            const removeParkingLot = {
              parkingLot: row.name,
              principal: row.principal ? row.principal.name : null,
            };

            this.$store
              .dispatch("parkingLot/remove", removeParkingLot)
              .then(async (response) => {
                this.$bvToast.toast("Parking Lot deleted successfully", {
                  title: `Success`,
                  variant: "success",
                  solid: true,
                });

                await this.$store.dispatch("parkingLot/get", this.$bvToast);
              })
              .catch((err) => {
                this.$bvToast.toast(err.message, {
                  title: `Error occured`,
                  variant: "danger",
                  solid: true,
                  toaster: "b-toaster-bottom-right",
                });
              });
          }
        });
    },
  },
};
</script>

<style lang="scss" >
@import "@core/scss/vue/libs/vue-good-table.scss";
@import "@core/scss/vue/libs/vue-select.scss";

.pac-container.pac-logo {
  z-index: 999999;
}

[dir] .vs__dropdown-toggle {
  border: 1px solid #d8d6de !important;
}

.scenes-select {
  .vs__selected {
    background-color: #7367f0 !important;
    cursor: auto !important;

    .vs__deselect {
      display: none !important;
    }
  }

  .vs__search {
    cursor: auto !important;
  }
}

#snapshot-scene-viewer {
  .modal-dialog {
    max-width: fit-content !important;
  }

  .maximized {
    max-width: fit-content !important;
    // max-width: 94% !important;
  }

  .minimized {
    max-width: 500px !important;
  }
}

.fabric-canvas-itself {
  position: absolute;
  top: 38px;
  // left: 23px
}

.fabric-canvas-itself.canvas-maximized {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  // width: 100%;
  // height: 100%;
}

// .snapshot-wrapper.snapshot-wrapper-maximized {
//     position: fixed;
//     background: #f8f8f8;
//     width: 100%;
//     left: 0;
//     top: 50%;
//     transform: translate(0, -50%);
//     // height: 100%;
// }

#snapshot-img.snapshot-img-maximized {
  object-fit: contain;
  border: 0;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  // width: 100%;
  height: 100%;
}

.max-icon {
  position: fixed !important;
  top: 15px;
  right: 15px;
  padding: 5px;
  z-index: 10000000;
}

.btn-icon {
  padding: 3px !important;
}
</style>
